<template>
  <v-card v-if="timeline" color="grey lighten-4" flat>
    <v-card-text v-if="!division.hydrated" class="text-center pt-9">
      <v-progress-circular indeterminate color="color3"></v-progress-circular>
    </v-card-text>
    <v-card-text v-else-if="!timeline.rounds.length" class="pa-12 text-center black--text">
      <h1 v-if="!timeline.division.adults">Booo!</h1>
      <h1 v-if="!timeline.division.adults"><v-icon x-large color="black">fas fa-frown</v-icon></h1>
      <h1>There is no published schedule yet</h1>
    </v-card-text>
    <v-card-text class="pa-1" v-else>
      <v-row dense v-for="(round, i) in timeline.rounds" :key="i">
        <v-col cols="12">
          <v-card>
            <v-toolbar color="color2 color2Text--text" flat>
              <v-toolbar-title>
                {{round.title}}{{ round.subtitle ? ` - ${round.subtitle}` : '' }}
                <div class="caption">{{round.description}}</div>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip left v-if="round.to">
                <span>Jump to {{round.subtitle || round.title}}</span>
                <template v-slot:activator="{ on }">
                  <v-btn color="color1 color1Text--text" fab small v-on="on" @click.stop="goto(round.to)">
                    <v-icon small>fas fa-caret-right</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </v-toolbar>
            <v-card-text class="pa-0 pr-2 grey lighten-4">
              <v-timeline dense align-top>
                <team-timeline-item
                  v-for="(item, i) in round.matches"
                  :key="i"
                  :item="item"
                  :round="round"
                  :isLeague="tournament.isLeague"
                  fill-dot
                >
                </team-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import TeamTimelineItem from './TeamTimelineItemNew'

export default {
  props: ['team'],
  computed: {
    ...mapGetters(['tournament', 'getRoundJProps', 'getTeam']),
    division () {
      return this.tournament.getDivisionByTeam(this.team.id)
    },
    timeline () {
      const timeline = this.tournament.getTimeline(this.team.id)
      timeline.rounds.forEach(r => {
        const p = this.getRoundJProps(r.id)
        if (r.to && r.to.params) r.to.params.divisionId = timeline.division.id
        if (p && p.noRefs) {
          r.matches.forEach(m => {
            if (m.what === 'Ref') m.what = 'Sit'
            if (m.what === 'Reffed') m.what = 'Sat'
          })
          // r.matches = r.matches.filter(f => f.what !== 'Ref' && f.what !== 'Reffed')
        }
        r.matches.forEach(m => {
          this.addProps(m, r)
          if (m.matches) {
            m.matches.forEach(mm => {
              this.addProps(mm, r)
            })
          }
        })
      })

      return timeline
    }
  },
  methods: {
    addProps (m, r) {
      m.props = []
      m.roundN && m.props.push(`Round ${m.roundN}`)
      m.when && !r.hideTimes && m.props.push(m.when.format('ddd LT'))
      m.where && m.props.push(`Court ${m.where}`)
      if (r.to) {
        m.to = Object.assign({}, r.to, { query: { number: m.matchN } })
      }
    },
    goto (to) {
      this.$router.push(to)
      this.$emit('close-click')
    },
    teamName (ids) {
      return ids.map(id => {
        const t = this.getTeam(id)
        return t && t.name
      }).filter(f => !!f)
    }
  },
  components: {
    TeamTimelineItem
  }
}
</script>

<style>
.v-card__text {
  line-height: initial !important;
}
</style>
