<template>
  <v-timeline-item
    :color="item.complete ? 'green' : 'red'"
    fill-dot
  >
    <template v-slot:icon>
      <v-icon  color="white">
        {{ ['Beat', 'Lost to', 'Split with', 'Play'].includes(item.what) ? 'fal fa-volleyball-ball': item.what.startsWith('Ref') ? 'far fa-whistle' : item.what === 'TBD' ? 'far fa-question' : 'fas fa-loveseat'}}
      </v-icon>
    </template>
    <v-card @click.native="goto(item.to)">
      <v-container fluid class="py-1">
        <v-row dense v-if="!isLeague && round.subtitle && !round.subtitle.includes(item.round)">
          <v-col cols="12" class="error--text">
            <b>{{item.round}}</b>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="py-0" cols="12">
            <div>
              <strong>{{item.what}}</strong> {{teamName(item.who) | formatArray}}
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" class="text-truncate py-0">
            <div v-if="item.wl">
              {{item.scores.find(f => f === '0-0') ? '' : `${item.scores.join(', ')}`}}
            </div>
            <div v-else-if="!item.complete">
              {{item.status}}
            </div>
          </v-col>

          <template v-if="item.matches">
            <v-col cols="12" class="py-0" v-for="(m, i) in item.matches" :key="i">
              <div class="py-0 grey--text text-right" style="font-size: .75em">{{getProps(m, round)}}</div>
            </v-col>
          </template>
          <v-col cols="12" class="py-0" v-else>
            <div class="py-0 grey--text text-right" style="font-size: .75em">{{getProps(item, round)}}</div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-timeline-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['item', 'round', 'isLeague'],
  computed: {
    ...mapGetters(['getTeam'])
  },
  methods: {
    goto (to) {
      if (!to) return
      this.$router.push(to)
      this.$emit('close-click')
    },
    teamName (ids) {
      if (!ids) return null
      return ids.map(id => {
        const t = this.getTeam(id)
        return t && t.name
      }).filter(f => !!f)
    },
    getProps (m, r) {
      const props = []
      m.matchN && m.matchN > 999 && props.push(`Pair ${m.matchN % 1000}`)
      m.roundN && props.push(`Round ${m.roundN}`)
      m.when && !r.hideTimes && props.push(m.when.format('ddd LT'))
      m.where && props.push(`Court ${m.where}`)
      return props.join(' | ')
    }
  }
}
</script>

<style>
.v-card__text {
  line-height: initial !important;
}
</style>
